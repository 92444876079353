// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/

@import "./fonts/remixicon.scss";
* {
  font-family: "Montserrat",sans-serif;
}
:root {
  /** primary **/
  --ion-color-primary: #e2800f;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #61d092;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #ff583b;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ff583b;
  --ion-color-danger-tint: #ff583b3a;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #61d092;
  --ion-color-medium-rgb: 253, 220, 115;
  --ion-color-medium-contrast: #333;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-step-50: transparent;
  /** light **/
  --ion-color-light: rgb(233, 233, 233);
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --st-scrollable-toolbar-padding-left: 0;
  --ion-background-color: rgb(249, 249, 252);
}

.grid {
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
  grid-gap: 3px;
}
.grid ion-card {
  min-height: calc(100% - 15px);
  margin-left: 0;
  margin-right: 0;
}
ion-card {
  border: none;
  box-shadow: none;
  background: #fff;
}
.list-stars {
  color:#ffbe00;
}
.stock-status > * {
  padding: 3px 5px;
  line-height: 25px;
  border-radius: 3px;
}

.list ion-card > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list ion-card > div > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.rotating {
  transform: rotate(720deg);
  border-radius: 100%;
  overflow: hidden;
  transition: 0.5s;
  color: var(--ion-color-primary);
}
.product-list {
  transition: 0.3s;
  background: #fff;
}

.product-list.loading {
  opacity: 0;
}
ion-toolbar {
  --background: #fff;
  --border-width: 1px;
  --border-color: #fff;
}
.sticky {
  position: sticky;
  top: -5px;
}
ion-tab-bar {
  --background: #fff;
}
super-tabs-toolbar {
  background-color: #fff;
  --st-indicator-color:var(--ion-color-primary);
}
super-tab-button.active {
  color: var(--ion-color-primary);
}
ul.list-unstyled {
  list-style: none;
  padding: 0;
  color: #777;
}
ion-button {
  border-radius: 7px;
  overflow: hidden;
  box-shadow: none;
  border: none;
  --border-width: 0;
  --box-shadow: none;
}
ion-item {
  --background: #fff;
}
ion-slide {
  text-align: initial !important;
}
.module-title {
  font-weight: bold;
  font-size: 14px;
  position: initial;
  width: 100%;
  text-align: left;
  padding: 0 15px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.module-title small {
  font-size: 12px;
  text-transform: uppercase;
}
.option ion-input, .option ion-textarea, .coupon, .comment {
  background: #f9f9fc !important;
}

ion-list-header {
  font-size: 14px;
}
ion-list.ios.list-ios.hydrated {--border-width: 0px;--border-color: #fff0;
  --border-width: 0px;
  --inner-border-width: 0px;}

ion-tab-bar {--border-color: #fff0;
  --border-width: 0px;
  --inner-border-width: 0px;
  border: 0px;
}
.filter-text {
  color: #333;
  font-weight: bold;
  padding-left: 5px;
  font-size: 12px;
}
.sticky-bar {
  background-color: #fff;
}
ion-list-header {
  margin-bottom: 5px;
  margin-top: 10px;
}
ion-content ion-title {
  width: 100%;
  padding: 0;
  text-align: left;
}

.fade-height {
  max-height: 0px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out .1s, max-height 0.3s  ease-in-out;
}
.fade-height.show {
  max-height: 100%;
  display: initial;
  opacity: 1;
}
ion-loading.ocionic-loader {
  --background: #fff;
  .loading-wrapper {
    background: transparent;
    box-shadow: none;
  }
}
.has-error {
  border-left: 3px solid var(--ion-color-danger);
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    border-color: transparent;
  }
}
ion-progress-bar {
  position: absolute;
  z-index: 99;
  top: 0;
}
ion-title {
  font-size: 15px;
  font-weight: bold;
}
h1.heading_title {
  font-weight: bold;
  font-size: 18px;
}